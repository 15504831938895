import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Header from '../components/header';
import SEO from '../components/seo';

const FlashcardsPage = ({ data }) => {
  const allFlashcards = data.allMarkdownRemark.edges;
  const [filteredFlashcards, setFilteredFlashcards] = useState(allFlashcards);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentFlashcard, setCurrentFlashcard] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');

  useEffect(() => {
    // Extract unique categories from flashcards
    const uniqueCategories = Array.from(new Set(allFlashcards.flatMap(f => f.node.frontmatter.categories || [])));
    setCategories(['All', ...uniqueCategories]);
  }, [allFlashcards]);

  // Filter flashcards based on category and search term
  useEffect(() => {
    let filtered = allFlashcards;

    if (selectedCategory !== 'All') {
      filtered = filtered.filter(f => f.node.frontmatter.categories?.includes(selectedCategory));
    }

    if (searchTerm) {
      filtered = filtered.filter(f =>
        f.node.frontmatter.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
        f.node.frontmatter.answer.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredFlashcards(filtered);
    setCurrentFlashcard(0); // Reset to first flashcard
  }, [selectedCategory, searchTerm, allFlashcards]);

  // Flip flashcard between question and answer
  const handleFlip = () => {
    setShowAnswer(!showAnswer);
  };

  // Move to the next flashcard
  const handleNextFlashcard = () => {
    setShowAnswer(false); // Reset the flip
    setCurrentFlashcard((prevIndex) => (prevIndex + 1) % filteredFlashcards.length);
  };

  return (
    <Layout>
      <SEO title="Flashcards" />
      <Header metadata={data.site.siteMetadata} />
      <h1>Flashcards</h1>

      {/* Search Bar (above categories) */}
      <div>
        <input
          type="text"
          placeholder="Search flashcards..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Category Filter */}
      <div>
        <label htmlFor="categorySelect">Filter by Category:</label>
        <select
          id="categorySelect"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {categories.map(category => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      {/* Display Flashcard */}
      {filteredFlashcards.length > 0 ? (
        <div className="flashcard-container" onClick={handleFlip}>
          <div className={`flashcard ${showAnswer ? 'show-answer' : ''}`}>
            <div className="front">
              <h3>{filteredFlashcards[currentFlashcard].node.frontmatter.question}</h3>
            </div>
            <div className="back">
              <h3>{filteredFlashcards[currentFlashcard].node.frontmatter.answer}</h3>
            </div>
          </div>
        </div>
      ) : (
        <p>No flashcards found matching your criteria.</p>
      )}

      {/* Next Flashcard Button */}
      {filteredFlashcards.length > 0 && (
        <button onClick={handleNextFlashcard}>Next Flashcard</button>
      )}

      <style jsx>{`
        .flashcard-container {
          display: flex;
          justify-content: center;
          align-items: center;
          perspective: 1000px;
          cursor: pointer;
          margin: 20px auto;
          height: 300px;
        }
        .flashcard {
          width: 300px;
          height: 200px;
          transition: transform 0.8s;
          transform-style: preserve-3d;
          position: relative;
        }
        .show-answer {
          transform: rotateY(180deg);
        }
        .front, .back {
          backface-visibility: hidden;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f9f9f9;
          border: 1px solid #000;
          border-radius: 10px;
          padding: 20px;
        }
        .back {
          transform: rotateY(180deg);
        }
      `}</style>
    </Layout>
  );
};

export default FlashcardsPage;

export const query = graphql`
  query FlashcardsPageQuery {
    site {
      siteMetadata {
        name
        title
        description
        about
        medium
        github
        linkedin
      }
    }
    allMarkdownRemark(filter: { frontmatter: { question: { ne: null } } }) {
      edges {
        node {
          frontmatter {
            question
            answer
            categories  # Fetching categories for filtering
            tags        # Fetching tags for potential skills mapping
          }
        }
      }
    }
  }
`;


